import React from 'react';
import ImgComponent from '../imgComponent';

const AnalyticalContent = ({ data }) => {
	return (
		<section
			className="elementor-element elementor-element-924d4d1 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
			data-id="924d4d1"
			data-element_type="section"
			data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;}"
		>
			<div className="elementor-container elementor-column-gap-default">
				<div className="elementor-row">
					<div
						className="elementor-element elementor-element-2e1f0cf elementor-column elementor-col-100 elementor-top-column"
						data-id="2e1f0cf"
						data-element_type="column"
						data-settings="{&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
					>
						<div className="elementor-column-wrap elementor-element-populated">
							<div className="elementor-widget-wrap">
								<div
									className="elementor-element elementor-element-14aaa15 elementor-widget elementor-widget-avante-gallery-grid"
									data-id="14aaa15"
									data-element_type="widget"
									data-settings="{&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
									data-widget_type="avante-gallery-grid.default"
								>
									<div className="elementor-widget-container">
										<div className="avante-gallery-grid-content-wrapper layout-avante-four-cols ">
											<div
												className="gallery-grid-item avante-four-cols emir"
												data-delay={10}
												data-minwidth={769}
												data-scale-x={0}
												data-scale-y={0}
											>
												<a
													className="tg_gallery_lightbox"
													href="/static/35c265339957e0dc5f8551025656a3f8/c5f21/Analytic1.jpg"
													data-thumb="/static/35c265339957e0dc5f8551025656a3f8/c5f21/Analytic1.jpg"
													data-rel="tg_gallery14aaa15"
												>
													<ImgComponent pmlImg="Analytic1.jpg" />
												</a>
											</div>
											<div
												className="gallery-grid-item avante-four-cols emir"
												data-delay={10}
												data-minwidth={769}
												data-scale-x={0}
												data-scale-y={0}
											>
												<a
													className="tg_gallery_lightbox"
													href="/static/0db7fe1286b0a1901fff3e2bedbfa168/c5f21/Analytic2.jpg"
													data-thumb="/static/0db7fe1286b0a1901fff3e2bedbfa168/c5f21/Analytic2.jpg"
													data-rel="tg_gallery14aaa15"
												>
													<ImgComponent pmlImg="Analytic2.jpg" />
												</a>
											</div>
											<div
												className="gallery-grid-item avante-four-cols emir"
												data-delay={10}
												data-minwidth={769}
												data-scale-x={0}
												data-scale-y={0}
											>
												<a
													className="tg_gallery_lightbox"
													href="/static/2b654266855d629f5d7a8a6256f8471a/c5f21/Analytic3.jpg"
													data-thumb="/static/2b654266855d629f5d7a8a6256f8471a/c5f21/Analytic3.jpg"
													data-rel="tg_gallery14aaa15"
												>
													<ImgComponent pmlImg="Analytic3.jpg" />
												</a>
											</div>
											<div
												className="gallery-grid-item avante-four-cols last emir"
												data-delay={10}
												data-minwidth={769}
												data-scale-x={0}
												data-scale-y={0}
											>
												<a
													className="tg_gallery_lightbox"
													href="/static/b4ff51c797feeba55d8610e780b04921/c5f21/Analytic4.jpg"
													data-thumb="/static/b4ff51c797feeba55d8610e780b04921/c5f21/Analytic4.jpg"
													data-rel="tg_gallery14aaa15"
												>
													<ImgComponent pmlImg="Analytic4.jpg" />
												</a>
											</div>
											<div
												className="gallery-grid-item avante-four-cols emir"
												data-delay={10}
												data-minwidth={769}
												data-scale-x={0}
												data-scale-y={0}
											>
												<a
													className="tg_gallery_lightbox"
													href="/static/8ce2132d44349e30fe9ea3dca22b970f/c5f21/Analytic5.jpg"
													data-thumb="/static/8ce2132d44349e30fe9ea3dca22b970f/c5f21/Analytic5.jpg"
													data-rel="tg_gallery14aaa15"
												>
													<ImgComponent pmlImg="Analytic5.jpg" />
												</a>
											</div>
											<div
												className="gallery-grid-item avante-four-cols emir"
												data-delay={10}
												data-minwidth={769}
												data-scale-x={0}
												data-scale-y={0}
											>
												<a
													className="tg_gallery_lightbox"
													href="/static/750eae0cd687983b63c52d585598ced2/c5f21/Analytic6.jpg"
													data-thumb="/static/750eae0cd687983b63c52d585598ced2/c5f21/Analytic6.jpg"
													data-rel="tg_gallery14aaa15"
												>
													<ImgComponent pmlImg="Analytic6.jpg" />
												</a>
											</div>
											<div
												className="gallery-grid-item avante-four-cols last emir"
												data-delay={10}
												data-minwidth={769}
												data-scale-x={0}
												data-scale-y={0}
											>
												<a
													className="tg_gallery_lightbox"
													href="/static/a9f7c1658d50f5571811df003b22b34d/c5f21/Analytic7.jpg"
													data-thumb="/static/a9f7c1658d50f5571811df003b22b34d/c5f21/Analytic7.jpg"
													data-rel="tg_gallery14aaa15"
												>
													<ImgComponent pmlImg="Analytic7.jpg" />
												</a>
											</div>
											<div
												className="gallery-grid-item avante-four-cols emir"
												data-delay={10}
												data-minwidth={769}
												data-scale-x={0}
												data-scale-y={0}
											>
												<a
													className="tg_gallery_lightbox"
													href="/static/0944367c3561cc9e3c1b6cd70dc31881/c5f21/Analytic8.jpg"
													data-thumb="/static/0944367c3561cc9e3c1b6cd70dc31881/c5f21/Analytic8.jpg"
													data-rel="tg_gallery14aaa15"
												>
													<ImgComponent pmlImg="Analytic8.jpg" />
												</a>
											</div>
											<div
												className="gallery-grid-item avante-four-cols emir"
												data-delay={10}
												data-minwidth={769}
												data-scale-x={0}
												data-scale-y={0}
											>
												<a
													className="tg_gallery_lightbox"
													href="/static/d8f25e09672762406445a32e16c22356/c5f21/Analytic9.jpg"
													data-thumb="/static/d8f25e09672762406445a32e16c22356/c5f21/Analytic9.jpg"
													data-rel="tg_gallery14aaa15"
												>
													<ImgComponent pmlImg="Analytic9.jpg" />
												</a>
											</div>
											<div
												className="gallery-grid-item avante-four-cols emir"
												data-delay={10}
												data-minwidth={769}
												data-scale-x={0}
												data-scale-y={0}
											>
												<a
													className="tg_gallery_lightbox"
													href="/static/b19017720b4c3a09578083e688913d3b/c5f21/Analytic10.jpg"
													data-thumb="/static/b19017720b4c3a09578083e688913d3b/c5f21/Analytic10.jpg"
													data-rel="tg_gallery14aaa15"
												>
													<ImgComponent pmlImg="Analytic10.jpg" />
												</a>
											</div>

											<br className="clear" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AnalyticalContent;
